.detailsBox {
  padding: 18px 20px 19px 20px;
  width: calc(50% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f4f4f4;
}

.detailsBox .title2 {
  color: #001283;
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
}

.detailsBox .titleContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.detailsBox .titleContainer2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.detailsBox .subtitle1 {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.462px;
}

.detailsBox .subtitle2 {
  color: #7f7f7f;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.462px;
}

@media (max-width: 600px) {
  .detailsBox {
    width: calc(100% - 60px);
  }

  .detailsBox .titleContainer {
    gap: 20px;
  }

  .detailsBox .titleContainer2 {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
}
