.propertyItem {
  padding: 35px 37px 25px 37px;
  width: calc(100% - 74px);
  border-radius: 10px;
  border: 0.8px solid #ebedf0;
  background: #fff;
  box-shadow: 0px 8px 28px -6px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.propertyItem .itemContent {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.propertyItem .align1 {
  display: flex;
  align-items: center;
  gap: 14px;
}

.propertyItem .align1 .title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0.2px;
}

.propertyItem .align1 .tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16.918px;
  background: rgba(59, 76, 184, 0.11);
  color: #001283;
  text-align: center;
  font-family: "Roboto";
  font-size: 16.918px;
  font-weight: 500;
  line-height: 140%;
  box-sizing: border-box;
  padding: 0.705px 7.754px;
}

.propertyItem .align2 {
  display: flex;
  align-items: center;
  gap: 5.25px;
}

.propertyItem .align2 .location {
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
}

.propertyItem .actionBtn {
  padding: 0;
  min-width: 0;
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .propertyItem {
    padding: 20px 10px;
    width: calc(100% - 14px);
  }

  .propertyItem .align1 .title {
    font-size: 14px;
  }

  .propertyItem .align2 .location {
    font-size: 12px;
  }

  .propertyItem .align1 .tag {
    font-size: 12px;
    padding: 5px 15px;
  }

  .propertyItem .actionBtn {
    align-items: end;
    height: 100%;
  }
}
