.box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.box2 {
  margin: 50px auto;
  max-width: 554px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box3 {
  margin-right: auto;
  margin-top: 29px;
  width: 100%;
}

.box4 {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  align-content: space-between;
  gap: 35px;
}

@media (max-width: 600px) {
  .box4 {
    gap: 25px;
  }
}
