.propertyItemContainer {
  width: 25%;
  max-width: 349px;
  color: #000;
  gap: 19px;
  padding: 26px 28px 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 8px 28px -6px rgba(0, 0, 0, 0.15);
}

.propertyItemContainer .propertyItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
}

.propertyItemContainer .propertyItem .title {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.2px;
}

.propertyItemContainer .propertyItem .propertyInfo {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.propertyItemContainer .propertyItem .propertyInfo .location {
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 400;
  line-height: 110%;
}

.propertyItemContainer .propertyItem .propertyInfo .tag {
  display: flex;
  min-width: 61px;
  padding: 0.5px 5.5px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radi-lg, 12px);
  background: rgba(59, 76, 184, 0.11);
  color: #001283;
  text-align: center;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  margin-left: 10px;
}

.propertyItemContainer .propertyItemActions {
  background-color: #001283;
  width: 100%;
  padding: 12px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.propertyItemContainer .propertyItemActions .btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f4f4f4;
  border: none;
  cursor: pointer;
}

.propertyItemContainer .propertyItemActions .details {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 600px) {
  .propertyItemContainer {
    width: 80%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .propertyItemContainer {
    width: 250px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .propertyItemContainer {
    width: 250px;
  }
}
