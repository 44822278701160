.sortingBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
}

.sortingBar .searchBar {
  max-width: 418px;
  height: 51px;
  width: 418px;
  box-sizing: border-box;
  border-radius: 50px;
  background: #fff;
  color: #7f7f7f;
  font-weight: 400;
  line-height: 100%;
  padding: 0px;
}

@media (max-width: 900px) {
  .sortingBar {
    margin-top: 50px;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }

  .sortingBar .searchBar {
    width: 100%;
    align-self: flex-start;
  }
}
