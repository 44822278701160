.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 33px;
  margin: 33px 0px;
  padding: 0px 121.5px;
}

.section .infoText {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.2px;
  width: 617px;
}
