.tenancyTableContainer {
  margin-top: 11px;
  border-radius: 20px !important;
  background: #fff;
}

.tenancyTableContainer .tableHeader {
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  padding: 27px 23px 24px 25px;
}

.tenancyTableContainer .tableCell {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  border: none;
  padding: 25px;
}

.tenancyTableContainer .tableRow .actionBtn {
  padding: 0;
  min-width: 0;
  width: auto;
  height: 30px;
}
