.table {
  border-radius: 15px;
  overflow: visible;
}

.tableHead {
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  border: 1px solid #e3e3e3;
  background: #f4f4f4;
}

.tableHeaderCell {
  color: #000;
  padding: 10px;
}

.tableCell {
  border: 1px solid #e3e3e3;
  padding: 10px;
  background: #fff;
}

.chequeImage {
  background: url("../../../../../../../assets/images/cheque.png") lightgray
    0.226px 0px / 100.195% 116.667% no-repeat;
  width: 52px;
  height: 21.972px;
  padding: 10px;
  flex-shrink: 0;
}

.table .addRows {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 0 0 15px 15px;
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  padding-top: 56.82px;
  padding-left: 10px;
  padding-bottom: 17.8px;
  display: flex;
  align-items: center;
  gap: 6px;
}
