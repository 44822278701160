.dialog {
  padding: 0px;
  margin: 0 auto;
}

.dialog .title {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 24px 24px 28px 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 2px solid #f5f5f5;
}

.dialog .description {
  color: #7f7f7f;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  padding: 24px 24px !important;
  border-bottom: 2px solid #f5f5f5;
}

.dialog .actions {
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.dialog .actions .btn {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: none;
}

.dialog .actions .cancelBtn {
  border: 2px solid #f5f5f5;
  color: #000;
}

.dialog .actions .deleteBtn {
  background-color: #001283;
  color: #fff;
}
