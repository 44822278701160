.box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box2 {
  margin: 50px auto;
  max-width: 554px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box3 {
  margin-right: auto;
  margin-top: 40px;
  max-width: 554px;
  width: 100%;
}

.box4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 554px;
  text-align: left;
}

.box5 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .box1 {
    padding: 0px;
  }

  .box2 {
    padding-left: 0;
    margin-top: 80px;
  }
}
