.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 33px;
  margin: 33px 0px;
  padding: 0px 121.5px;
}

.section .infoText {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.2px;
}

.section .field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 514px;
}

.section .field .label {
  color: #696f79;
  font-size: 16px;
  font-weight: 500;
}

.section .spacing1 {
  margin-top: 20px;
}

.section .resendText {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
