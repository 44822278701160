.subTitleBar {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subTitleBar .subTitle {
  color: #020615;
  font-size: 32px;
  font-weight: 500;
  line-height: 110%;
}

.subTitleBar .subTitleActions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.subTitleBar .addPropertyButton {
  width: 196px;
  height: 51px;
  border-radius: 50px;
  background-color: #fff;
  color: #020615;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  padding: 3px 15px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 3px;
  box-shadow: none;
}

.subTitleBar .addPropertyButton:hover {
  color: #fff;
  background-color: #001283;
}

.addPropertyButton:hover svg path {
  fill: #fff;
}

.subTitleActions .searchBar {
  max-width: 370px;
  height: 51px;
  width: 100%;
  border-radius: 50px;
  background: #fff;
  color: #7f7f7f;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  padding: 0px;
}

@media (max-width: 600px) {
  .subTitleBar {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 50px;
  }

  .subTitleBar .subTitle {
    font-size: 22px;
  }

  .subTitleBar .subTitleActions {
    flex-direction: column;
    width: 100%;
  }
  .subTitleBar .subTitleActions .addPropertyButton {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .subTitleBar {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 40px;
  }

  .subTitleBar .subTitle {
    font-size: 24px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .subTitleBar {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 40px;
  }

  .subTitleBar .subTitle {
    font-size: 22px;
  }
}
