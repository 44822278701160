.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.box1 {
  width: 28px;
  height: 55px;
}

.box2 {
  display: "flex";
}

.div1 {
  display: "flex";
}

.div2 {
  display: flex;
  align-items: flex-end;
}

.div3 {
  width: 33.5px;
  height: 33px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: rotate(360deg);
}

h2.content {
  margin: 32px 0px 0px 20px;
}
