.item {
  box-sizing: border-box;
  border-radius: 6px;
  outline: 1px solid #001283;
  background: #f5f9ff;
  box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.04);
  transform: scale(1.02);
}

.tile {
  max-width: 486px;
  height: 124px;
  cursor: pointer;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.tile .action {
  align-self: center;
}

.iconContainer:hover .rightArrowIcon {
  transform: translateX(5px);
  transition: transform 0.3s ease;
}

@media (max-width: 600px) {
  .tile {
    height: auto;
  }
}
