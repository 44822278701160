.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  margin: 60px auto 0px;
  height: 85vh;
  max-width: 1100px;
}

.scrollableContent {
  max-width: 965px;
  max-height: 408px;
  overflow-y: scroll;
  scrollbar-width: 11px;
  scrollbar-color: #001283 #f8f8f8;
}

.dialogActions {
  display: flex;
  align-items: center;
  padding: 8px 16px !important;
}

.checkboxTextContainer {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .dialog {
    margin: 20px 0px;
  }

  .buttonsContainer {
    gap: 20px;
  }

  .dialogActions {
    flex-direction: column;
    padding: 24px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .dialog {
    margin: 20px 0px;
    height: 90vh;
  }

  .dialogActions {
    flex-direction: column;
    padding: 24px;
  }

  .buttonsContainer {
    justify-content: flex-end;
    width: 100%;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .dialog {
    margin: 40px 0px;
  }

  .dialogActions {
    flex-direction: column;
    padding: 24px;
  }

  .buttonsContainer {
    justify-content: flex-end;
    width: 100%;
  }
}
