.box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
}

.box2 {
  margin: auto;
  max-width: 554px;
}
