.dialog {
  padding: 0px;
  margin: 0 auto;
}

.dialog .titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 2px solid #f5f5f5;
  padding: 20px;
}

.dialog .title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.dialog .dialogContent {
  padding: 0px 71px;
}

.dialog .dialogContent .description {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.2px;
  margin-top: 33px;
}

.dialog .dialogContent .contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 24px;
  gap: 20px;
  margin-top: 33px;
}

.dialog .contentContainer .fileUploadContainer {
  padding: 45px 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  border-radius: 8px;
  border: 1px dashed #3b4cb8;
  background: #fff;
  cursor: grab;
}

.dialog .contentContainer .fileUploadContainer:hover {
  background: #e7ecfc;
}

.dialog .contentContainer .fileUploadContainer .uploadText {
  color: #484848;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.dialog .contentContainer .formatText {
  color: #9d9d9d;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.dialog .dialogContent .contentContainer .templateText {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.dialog .dialogContent .contentContainer .templateLink {
  color: #001283 !important;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
}

.dialog .contentContainer .downloadBtn {
  border-radius: 20px;
  border: 1px solid #001283;
  background: #fff;
  color: #001283;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: none;
}

.dialog .contentContainer .downloadBtn:hover {
  border-radius: 20px;
  border: 1px solid #001283;
  color: #fff;
  background: #001283;
}

.dialog .actions {
  padding: 14px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-top: 2px solid #f5f5f5;
  margin-top: 33px;
}

.dialog .actions .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: none;
  padding: 14px 24px;
  height: 44px;
}

.dialog .actions .cancelBtn {
  border-radius: 20px;
  border: 1px solid #cbcbcb;
  background: #fff;
  color: #232222;
}

.dialog .actions .actionBtn {
  background-color: #cdcdcd;
  color: #fff;
  box-shadow: none;
}

@media (max-width: 600px) {
  .dialog .dialogContent {
    padding: 0px 20px;
  }

  .dialog .dialogContent .description {
    font-size: 14px;
  }

  .dialog .contentContainer .fileUploadContainer {
    padding: 15px 25px;
  }

  .dialog .contentContainer .formatText {
    font-size: 12px !important;
  }

  .dialog .dialogContent .contentContainer .templateText {
    font-size: 12px;
  }

  .dialog .contentContainer .downloadBtn {
    font-size: 10px;
    font-weight: bold;
    width: 100%;
  }
}
