.leftGrid {
  width: 33.333333%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.52)),
    url("../../assets/images/building.png");
  background-size: cover;
  background-position: center;
  border-right: 1px solid #e0e0e0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.rightGrid {
  background-color: white;
  margin-left: 33.333333% !important;
  padding: 40px;
}

.loginImage {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../assets/images/building2.jpg") no-repeat center center / cover;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 600px) {
  .leftGrid {
    position: relative;
  }

  .rightGrid {
    height: auto;
    margin-left: 0 !important;
    padding: 20px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .leftGrid {
    position: relative;
  }

  .rightGrid {
    height: auto;
    margin-left: 0 !important;
    padding: 20px;
  }
}
