.billingSection {
  margin-top: 24px;
}

.billingSection .billingHistory {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
}

.billingSection .noBillingHistory {
  color: #2c2c2c;
  font-size: 16px;
  text-align: center;
  width: 100%;
}

.billingSection .billingHistoryDescription {
  color: #696969;
  font-size: 10px;
  font-weight: 500;
  margin-top: 8px;
  margin-left: 16px;
}

.tableHeader {
  color: #696969;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.7;
  cursor: pointer;
  border-bottom: none !important;
}

.tableRow {
  background: #fafafa;
  font-size: 14px;
  font-weight: 400;
}

.tableData {
  color: #2c2c2c;
  font-family: "Work Sans";
  margin-top: 15px;
  border: none !important;
}

.billingSection .tag {
  display: inline-block;
  border-radius: 4px;
  background: rgba(114, 212, 0, 0.2);
  color: #07a104;
  padding: 0 16px;
}

.billingSection .link {
  color: #001283;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}
