.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 33px;
  margin: 33px 0px;
  padding: 0px 121.5px;
}

.section .feedbackText {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.2px;
}

.section .feedbackOption {
  display: flex;
  width: 663px;
  height: 55px;
  padding: 0px 20px;
  align-items: center;
  gap: 33px;
  border-radius: 6px;
  background: #eaeaec;
}

.section .feedbackOption .feedback {
  color: #838199;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.2px;
}
