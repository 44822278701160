.field {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field .align .label {
  color: #21272a;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

.field .align .smallText {
  color: #7f7f7f;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
}

.field .align .link {
  color: #003;
  font-size: 9px;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
}

.field .align .link:hover {
  color: #001283;
}

.field .textField {
  border-radius: 6px;
  width: 100%;
  height: 32px;
  flex-shrink: 1;
}

.field .datepicker {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  width: 330px;
  height: 32px;
  flex-shrink: 1;
  color: #7f7f7f;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .field .textField {
    width: 95%;
    flex-shrink: 0;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .field .textField {
    width: 85%;
    flex-shrink: 0;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .field {
    width: 200px !important;
    flex-shrink: 1;
  }
  .field .textField {
    width: 100%;
    flex-shrink: 0;
  }
}
