.propertyForm {
  width: 100%;
  background-color: #fff;
  margin-top: 34px;
  border-radius: 20px;
}

.header {
  padding: 18.38px 18px;
  border-bottom: 1px solid #e3e3e3;
}

.propertyForm .header .title {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;
}

.content {
  width: calc(100%-40px) !important;
  padding: 34px 21px 0px 19px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 14px 24px;
  border-radius: 0px 0px 20px 20px;
  border-top: 1px solid #e3e3e3;
  background: #fff;
  margin-top: 130px;
}

.footer .btn {
  border-radius: 20px;
  text-transform: none;
  height: 44px;
  padding: 14px 24px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.footer .btn.cancel {
  color: #232222;
  border: 1px solid #cbcbcb;
  background: #fff;
}

.footer .btn.save {
  color: #fff;
  background: #001283;
}

@media (max-width: 600px) {
  .propertyForm .content {
    width: calc(100% - 40px) !important;
  }

  .propertyForm .header .title {
    font-size: 16px;
  }
}
