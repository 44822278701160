.sideNav {
  height: 100vh;
  width: 256px;
  max-width: 256px;
  background-color: #fafafa;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.sideNav .navList {
  margin-top: 125px;
  padding: 0px 10px 0px 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
}

.sideNav .navList .navListItem {
  border-radius: 20px;
}

.sideNav .navList .activeListItem {
  background: linear-gradient(90deg, #e1e1e4 -34.58%, #fff 164.97%);
}

.sideNav .navList .navListItem .navBtn {
  padding: 12px;
}

.sideNav .navList .navListItem .navBtn:hover {
  border-radius: 20px;
  background: linear-gradient(90deg, #e1e1e4 -34.58%, #fff 164.97%);
  color: #001283;
}

.sideNav .navList .navListItem .navBtn .activeBtn {
  border-radius: 20px;
  color: #001283;
}

.sideNav .navList .navBtn .navItemText {
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

@media (max-width: 600px) {
  .sideNav {
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .sideNav {
    display: none;
  }
}
