.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 33px;
  margin: 33px 0px;
  padding: 0px 121.5px;
}

.section .infoText {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.2px;
  width: 511px;
}

.section .inputContainer {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.section .inputField {
  display: inline-flex;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #cecece;
  background: #fff;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}

.section .errorField {
  border: 1px solid #e80000;
}

.section .errorText {
  color: #e80000 !important;
}

.section .spacing1 {
  margin-top: 20px;
}

.section .resendText {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
